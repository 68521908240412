import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BlankLink } from "../../ui/BlankLink"
import { Container } from "../../ui/Container"
import {
  Article,
  FormattedDate,
  PostHeader,
  SubPostSubheader,
  SubPostTitle,
} from "../../ui/post"
import { PostList } from "../../ui/post-list"

const PostEntry = props => {
  const { frontmatter, fields } = props
  const { title, posted } = frontmatter
  const { slug } = fields

  const url = `/blog` + slug

  return (
    <Article as={BlankLink} to={url}>
      <PostHeader>
        <SubPostTitle as="h2">{title}</SubPostTitle>
        <SubPostSubheader>
          <FormattedDate date={posted} />
        </SubPostSubheader>
      </PostHeader>
    </Article>
  )
}

const AllBlogsPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO />
      <Container>
        <PostList as="main">
          {posts.map(edge => (
            <PostEntry key={edge.node.id} {...edge.node} />
          ))}
        </PostList>
      </Container>
    </Layout>
  )
}

export default AllBlogsPage

export const pageQuery = graphql`
  query AllPosts {
    allMarkdownRemark(
      sort: { fields: [frontmatter___posted], order: DESC }
      filter: { fields: { sourceName: { eq: "posts" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            posted
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
