import styled from "@emotion/styled"

export const PostCardList = styled.div`
  margin-bottom: 32px;

  & > * {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const PostList = styled.div`
  margin-bottom: 32px;

  & > * {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
