import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

export const ChipButtonElement = styled.button`
  display: block;
  -webkit-appearance: none;
  width: 100%;
  text-transform: uppercase;
  background: transparent;
  color: inherit;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  text-align: center;

  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.background.default};
  }
`

/**
 * Chip-style button button/link action
 */
export const Button = props => {
  const {
    ButtonElement = ChipButtonElement,
    newtab = false,
    children,
    ...other
  } = props
  const { to, href } = props
  const component = to ? Link : href ? "a" : "button"
  const extraProps = newtab ? { target: "_blank", rel: "noopener" } : {}

  return (
    <ButtonElement as={component} {...extraProps} {...other}>
      {children}
    </ButtonElement>
  )
}
