import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

export const BlankAnchor = styled.a`
  color: inherit;
  text-decoration: none;
`

export const BlankLink = props => {
  return <BlankAnchor {...props} as={Link} />
}
